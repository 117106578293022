<template>
  <b-modal
    id="modal-user"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal--large"
  >
    <div class="section">
      <header
        class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2"
      >
        <b-row>
          <b-col cols="3" class="d-flex align-items-center">
            <div style="cursor: pointer" @click="directPage">
              <feather-icon
                size="24"
                class="text-dark font-weight-bolder"
                icon="ArrowLeftIcon"
              />
              <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1"
                >Kembali</span
              >
            </div>
          </b-col>
          <b-col
            cols="6"
            class="d-flex justify-content-center align-items-center"
          >
            <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
              {{ editUuid === null ? "Tambah Pengguna" : "Informasi Pengguna" }}
            </h1>
          </b-col>
          <b-col cols="3" class="text-right">
            <b-button class="px-3" @click="editUuid !== null ? updateItem() : createItem()"> Simpan </b-button>
          </b-col>
        </b-row>
      </header>
      <b-container class="mt-5 pt-5">
        <b-row class="justify-content-center h-100 bg-white pb-5">
          <b-col cols="12" md="6">
            <b-row>
              <!-- Nama -->
              <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="v-nama"
                  >Nama <span class="text-danger">*</span></label
                >
                <b-form-group>
                  <b-form-input
                    id="v-nama"
                    v-model="formPayload.name"
                    type="text"
                    placeholder="Nama"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <!-- Cabang -->
              <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="v-cabang"
                  >Cabang <span class="text-danger">*</span></label
                >
                <b-form-group>
                  <v-select
                    id="v-cabang"
                    v-model="formPayload.branch_uuid"
                    label="name"
                    :options="optionsCabang"
                    placeholder="Pilih Cabang"
                    @search="onSearchCabang"
                  />
                </b-form-group>
              </b-col>
              <!-- Email -->
              <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="v-email">Email </label>
                <b-form-group>
                  <b-form-input
                    id="v-email"
                    v-model="formPayload.email"
                    type="email"
                    placeholder="Kota"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <!-- phone Number -->
              <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="v-phone"
                  >No. Handphone <span class="text-danger">*</span></label
                >
                <b-row>
                  <b-col cols="3" class="pr-0">
                    <v-select
                      v-model="formPayload.selectedCountry"
                      :label="currentLabel"
                      :options="countryList"
                      :clearable="false"
                      @keyup.stop.native="searchCountry"
                    >
                      <template
                        #selected-option="{ country_flag, dial_code_preview }"
                      >
                        <div class="d-flex align-items-center" style="gap: 6px">
                          <img
                            :src="country_flag"
                            :alt="dial_code_preview"
                            style="width: 24px; height: 24px"
                          />
                          <p class="size12 mb-0">({{ dial_code_preview }})</p>
                        </div>
                      </template>
                      <template #option="{ country_flag, dial_code_preview }">
                        <div class="d-flex align-items-center" style="gap: 8px">
                          <img
                            :src="country_flag"
                            :alt="dial_code_preview"
                            style="width: 24px; height: 24px"
                          />
                          <p class="mb-0">{{ dial_code_preview }}</p>
                        </div>
                      </template>
                    </v-select>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-input
                        id="v-phone"
                        v-model="formattedPhone"
                        type="tel"
                        placeholder="No. Handphone"
                        class="custom__input"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <!-- Password -->
              <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="v-password">Password</label>
                <b-form-group>
                  <b-form-input
                    id="v-password"
                    v-model="formPayload.password"
                    type="password"
                    placeholder="Password"
                    class="custom__textarea"
                  />
                </b-form-group>
              </b-col>
              <!-- Konfirmasi Password -->
              <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="v-c-password">Konfirmasi Password</label>
                <b-form-group>
                  <b-form-input
                    id="v-c-password"
                    v-model="formPayload.password_confirmation"
                    type="password"
                    placeholder="Konfirmasi Password"
                    class="custom__textarea"
                  />
                </b-form-group>
              </b-col>
              <!-- Status Berlangganan -->
              <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="v-renewal">Status Berlangganan</label>
                <b-form-group>
                  <b-form-group>
                    <b-form-select
                      id="v-renewal"
                      v-model="formPayload.renewal_status"
                      class="custom__textarea"
                    >
                      <b-form-select-option :value="null"
                        >Pilih Status Berlangganan</b-form-select-option
                      >
                      <b-form-select-option value="subscribe"
                        >Subscribe</b-form-select-option
                      >
                      <b-form-select-option value="trial"
                        >Trial</b-form-select-option
                      >
                      <b-form-select-option value="free"
                        >Gratis</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>
                </b-form-group>
              </b-col>
              <!-- Tanggal Berakhir -->
              <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="v-renewalEnd">Tanggal Berakhir</label>
                <b-form-group>
                  <b-form-datepicker
                    id="v-renewalEnd"
                    v-model="formPayload.renewal_end_date"
                    class="custom__input"
                    placeholder="Tanggal Berakhir"
                    locale="id"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <label>Role <span class="text-danger">*</span></label>
                    </div>
                    <div
                      v-for="role in roles"
                      :key="role.id"
                      class="col-3 mb-0"
                    >
                      <div class="form-group">
                        <b-form-checkbox
                          v-model="formPayload.roles"
                          class="text-capitalize text-dark mt-25"
                          :value="role.id"
                          @change="handleRoleSelection"
                        >
                          {{ role.name }}
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" md="12">
                <div class="form-group">
                  <div
                    v-for="(permissionItem, key) in permissions"
                    :key="key"
                    class="row mt-1"
                  >
                    <div class="col-12">
                      <label class="text-dark size14 mb-1"
                        ><strong>{{ permissionItem.group_name }}</strong></label
                      >:
                    </div>
                    <div
                      v-for="permission in permissionItem.permission_list"
                      :key="permission.id"
                      class="col-4 mb-0"
                    >
                      <div class="form-group">
                        <b-form-checkbox
                          :id="'permission_checkbox_' + permission.id"
                          v-model="formPayload.permissions"
                          class="text-capitalize text-dark"
                          :value="permission.id"
                          placeholder="Enter role name, example: finance"
                        >
                          {{ permission.name }}
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import {
  BModal,
  BContainer,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormSelect,
  BFormSelectOption,
  BFormDatepicker,
} from "bootstrap-vue";
import _ from "lodash";
import vSelect from "vue-select";
import moment from "moment";
import BaseInput from "@/components/BaseInput.vue";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BContainer,
    BaseInput,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BFormDatepicker,
    vSelect,
  },
  props: {
    formData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: "",
    },
    getData: {
      type: Function,
    },
    getDetail: {
      type: Function,
    },
    result: {
      type: Object,
    },
    detailResult: {
      type: Object,
    },
    editItem: {
      type: Function,
    },
    createItem: {
      type: Function,
    },
    updateItem: {
      type: Function,
    },
    editId: {
      type: Number,
    },
    cleanUpForm: {
      type: Function,
    },
  },
  data() {
    return {
      editUuid: null,
      formPayload: {
        email: "",
        password: "",
        password_confirmation: "",
        branch_uuid: "",
        phone: "",
        phone_country: "",
        renewal_status: "",
        renewal_end_date: "",
        roles: [],
        permissions: [],
        selectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
      },
      permissions: [],
      roles: [],
      optionsCabang: [],
      searchQuery: "",
      countryList: [],
    };
  },
  computed: {
    currentLabel() {
      return /^\d+$/.test(this.searchQuery)
        ? "dial_code_value"
        : "country_name";
    },
    dialCode() {
      const selectedCountry = this.countryList.find(
        (country) =>
          country.country_code ===
          this.formPayload.selectedCountry?.country_code
      );
      return selectedCountry ? selectedCountry.dial_code_value : "";
    },
    formattedPhone: {
      get() {
        if (!this.formPayload.phone) {
          return "";
        }
        const regex = new RegExp(`^${this.dialCode}`);
        return this.formPayload.phone.replace(regex, "");
      },
      set(value) {
        if (value.startsWith("0")) {
          value = value.substring(1);
        }
        this.formPayload.phone = this.dialCode + value;
      },
    },
  },

  watch: {
    formData(value) {
      this.formPayload = value;
    },
    editId(value) {
      this.editUuid = value;
    },
    formPayload: {
      handler(value) {
        this.$emit("getPayload", value);
      },
      deep: true,
    },
  },
  mounted() {
    this.getCabang();
    this.getRoles();
    this.getPermissions();
    this.getCountry();
  },
  methods: {
    // getPhoto(value) {
    //   this.formPayload.logo = value.photo;
    // },
    onSearchCabang(search, loading) {
      if (search.length) {
        loading(true);
        this.searchCabang(loading, search, this);
      } else {
        loading(true);
        setTimeout(() => {
          this.optionsCabang = [];
          this.getCabang();
          loading(false);
        }, 500);
      }
    },
    searchCabang: _.debounce((loading, search, vm) => {
      vm.$store
        .dispatch("cabang/getData", {
          uuid: "",
          params: {
            search,
          },
        })
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          vm.optionsCabang = [];
          response.data.data.data.forEach((item) => {
            vm.optionsCabang.push(item);
          });
          loading(false);
        });
    }, 300),
    async getCabang() {
      this.$store
        .dispatch("cabang/getData", {
          uuid: "",
          params: "",
        })
        .then((result) => {
          this.optionsCabang = result.data.data.data;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    searchCountry: _.debounce(function (e) {
      const search = e.target.value;
      this.searchQuery = search;
      this.getCountry(search);
    }, 300),
    getCountry(search) {
      const isDialCode = /^\d+$/.test(search);
      const params = isDialCode ? { search: search } : { search: search };

      this.$http
        .get("/country-phone-codes?per_page=500", { params })
        .then((response) => {
          this.countryList = response.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRoles() {
      this.$http
        .get("super-admin/user-merchant/role")
        .then((result) => {
          this.roles = result.data.data;
          console.log(this.roles, "data roles");
        })
        .catch(() => {});
    },
    getPermissions() {
      this.$http
        .get("super-admin/user-merchant/permission")
        .then((result) => {
          this.permissions = result.data.data;
          console.log(this.permissions, "data permission");
        })
        .catch(() => {});
    },
    handleRoleSelection() {
      const selectedRoles = this.formPayload.roles || [];
      const rolePermissionsMap = {};

      this.roles.forEach((role) => {
        rolePermissionsMap[role.id] = role.permissions || [];
      });

      const selectedPermissions = selectedRoles.reduce(
        (permissions, roleId) => {
          const rolePermission = rolePermissionsMap[roleId] || [];
          return permissions.concat(rolePermission);
        },
        []
      );

      const uniquePermissions = [...new Set(selectedPermissions)];

      this.formPayload.permissions = uniquePermissions;
    },
    directPage() {
      this.cleanUpForm();
      this.$bvModal.hide("modal-user");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

label {
  font-size: 14px;
  font-weight: 600;
  color: #2b324f;
}

.custom__input {
  height: 52px;
  background: #eff1f5;
  border: 1px solid #e4e5ec;
  border-radius: 8px;
  color: #2b324f;
  font-weight: 600;
  font-size: 14px;
}
</style>
