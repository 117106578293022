<template>
  <div class="section section__user">
    <div
      class="transaction__filter--container d-block bg-white bg-background-2 py-2"
    >
      <b-row class="search__export align-items-center">
        <b-col cols="8">
          <div class="d-flex pl-2 align-items-center">
            <b-img
              class="filter-image"
              :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
              :src="require('@/assets/images/icons/Icon-order-list-down.svg')"
              alt="Icon-order-list-down"
              role="button"
              @click="
                filter.sort_type == 'asc'
                  ? (filter.sort_type = 'desc')
                  : (filter.sort_type = 'asc')
              "
            />
            <div class="search__input w-100 pl-2 pr-0 mr-0">
              <b-input-group>
                <b-form-input v-model="filter.search" placeholder="" />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </b-col>
        <b-col cols="4" class="px-0 d-flex align-items-center" style="gap: 8px">
          <b-button class="border-8" @click="resetFilter"> Reset </b-button>
          <b-button class="border-8" @click="exportReport">
            Export Data
          </b-button>
          <b-button class="border-8" @click="addUser">
            Tambah Pengguna
          </b-button>
        </b-col>
      </b-row>
    </div>
    <vue-good-table
      v-if="result.data"
      max-height="100vh"
      :columns="columns"
      :rows="result.data"
      :fixed-header="false"
      :sort-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.field == 'action'" />
      </template>
      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <div class="d-flex">
              <!-- <b-button
                class="mr-1 px-1 border-0 bg-pink"
                @click="deleteData(props.row.id)"
              >
                <feather-icon
                  size="18"
                  class="text-danger"
                  icon="Trash2Icon"
                />
              </b-button> -->
              <b-button class="bg-white" @click="editItem(props.row.id)">
                Lihat Detail
              </b-button>
              <!-- <b-button class="bg-white" @click="editItem(props.row)">
                                Kelola User
                            </b-button> -->
            </div>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <div
      v-if="result.data"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10', '15', '20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan 1 ke {{ filter.per_page }} dari
          {{ result.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="result"
              :limit="4"
              :align="'right'"
              @pagination-change-page="getData"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormSelect,
  VBToggle,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";

import moment from "moment";
import FileSaver from "file-saver";

export default {
  components: {
    VueGoodTable,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    getData: {
      type: Function,
    },
    getDetail: {
      type: Function,
    },
    addUser: {
      type: Function,
    },
    result: {
      type: Object,
    },
    detailResult: {
      type: Object,
    },
    editItem: {
      type: Function,
    },
  },
  data() {
    return {
      progressValue: 35,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Nama Pengguna",
          field: "name",
        },
        // {
        //     label: 'Nama Cabang',
        //     field: 'cabang',
        // },
        {
          label: "Nama Toko",
          field: "merchant.name",
        },
        {
          label: "Nama Cabang",
          field: "branch.name",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
      items: [],
      search: "",
      filter: {
        search: "",
        sort_type: "asc",
        per_page: 10,
      },
    };
  },
  watch: {
    filter: {
      handler(value) {
        this.$emit("filterBranch", value);
      },
      deep: true,
    },
  },
  methods: {
    resetFilter() {
      this.filter = {
        search: "",
        sort_type: "desc",
        per_page: 10,
      };
    },
    // goToDetail(uuid) {
    //   this.getDetail(uuid);
    //   this.$bvModal.show("modal-user");
    // },
    async exportReport() {
      // const queryParams = filter

      const response = await this.$http.get(
        "/super-admin/user-merchant/export",
        {
          responseType: "blob",
          // params: queryParams,
        }
      );

      const dateDownload = new Date();

      const timestamp = moment(dateDownload).format("YYYYMMDD");
      let fileName = "";

      fileName = `daftar-pengguna-${timestamp}.xlsx`;
      FileSaver.saveAs(response.data, fileName);
      // console.log(response, ' report')
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.section__user {
  .vgt-responsive {
    height: calc(100vh - 235px) !important;
    background-color: #fff;
  }
}
</style>
